@import url('./Component/assets/css/common.css');
@import url('./Component/assets/css/layout.css');
@import url('./Component/assets/css/auth.css');
@import url('./Component/assets/css/dashboard.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.0/css/all.min.css');
/* @import url('./Component/assets/css/fontawesome.min.css'); */

body {
  margin: 0;
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FFFFFF;
  font-size: 14px;
  color: #212529;
  margin: 0;
  padding: 0;
}

/* ::-webkit-scrollbar {
  width: 0;
  background: transparent;
} */
.gradient-button {
  color: #212529;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 20px !important;
  text-align: center;
  border-radius: 10px;
  background: #fecc4e;
  cursor: pointer;
  outline: none;
  border: none;
  margin-top: 20px;
}
.gradient-button2 {
  color: #212529;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 20px !important;
  text-align: center;
  border-radius: 10px;
  background: #3ea0de;
  cursor: pointer;
  outline: none;
  border: none;
  margin-top: 20px;
}

.transparent-button {
  color: #212529;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px !important;
  text-align: center;
  border-radius: 30px;
  background: transparent;
  cursor: pointer;
  outline: none;
  border: 1px solid;
}

.filter-button {
  color: #212529;
  text-transform: capitalize;
  font-size: 11px;
  font-weight: 500;
  padding: 3px 7px !important;
  text-align: center;
  border-radius: 10px;
  background: #fecc4e;
  cursor: pointer;
  outline: none;
  border: none;
  /* height: 35px; */
}
.admin-button {
  color: #212529;
  text-transform: capitalize;
  font-size: 11px;
  font-weight: 500;
  padding: 3px 7px !important;
  text-align: center;
  border-radius: 5px;
  background: #fecc4e;
  cursor: pointer;
  outline: none;
  border: none;
  /* height: 35px; */
}

.clear-button {
  color: #212529;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 20px !important;
  text-align: center;
  border-radius: 10px;
  background: transparent;
  cursor: pointer;
  outline: none;
  border: 1px solid #fecc4e;
}

.scale-icon {
  max-width: 18px;
  height: auto;
}

.ant-modal-title {
  color: #212529;
}

.ant-modal-content {
  background: #FFFFFF;
  color: #212529;
}

.ant-select {
  color: #212529 !important;
  outline: none !important;
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  box-shadow: none;
}

.ant-select-selector {
  min-height: 35px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  border-radius: 0.5rem;
}

.status_active {
  font-size: 12px;
  font-weight: 700;
  color: #0E6F6B;
  background-color: #C9F7F5;
  border-color: #C9F7F5;
  border-radius: 8px;
  padding: 4px 10px 4px;
  cursor: pointer;
}

.status_inactive {
  font-size: 12px;
  font-weight: 700;
  color: #AA1C2B;
  background-color: #FFE2E5;
  border-color: #FFE2E5;
  border-radius: 8px;
  padding: 4px 10px 4px;
  cursor: pointer;
}

.status_inactive:hover {
  font-weight: 700;
  color: #AA1C2B;
  background-color: #FFE2E5;
  border-color: #FFE2E5;
  border-radius: 8px;
  cursor: pointer;
}

.edit-icon {
  max-width: 30px;
  height: auto;
  font-size: 25px;
  color: #1559a2;
}

.faq-icon {
  width: 50px;
  font-size: 25px;
  padding-left: 15px;
  color: #1559a2;
}

input[readonly] {
  background-color: #f7f7f7;
  border: unset;
}

input[readonly]:hover,
input[readonly]:focus {
  border: unset;
  box-shadow: unset;
}

.ant-menu-submenu-selected {
  fill: #1559A2;
}

.clear-button1 {
  z-index: 99;
}

.table tbody td {
  font-weight: 500;
  max-width: fit-content;
  min-width: auto;
  font-size: 13px !important;
}

.table thead th {
  font-size: 13px !important;
}

.ant-pagination-options {
  display: none;
}

.ant-form-item-control-input-content .wrapper {
  z-index: 999 !important;
}

.cmyNjn>.wrapper {

  /* height: 36px !important; */
  border: 1px solid #d4d4d4 !important;
  border-radius: 10px !important;
  min-height: 36px !important;
}

.cmyNjn>.wrapper:active {
  box-shadow: rgb(32 33 36 / 0%) 0px 1px 6px 0px !important;
}

.cmyNjn>.wrapper:hover {
  box-shadow: rgb(32 33 36 / 0%) 0px 1px 6px 0px !important;
}

.fDbOPw {
  min-height: 36px !important;
}

div#testid_erro {
  color: red;
}

.appli_view_label {
  font-size: 14px;
  font-weight: 500;
}

.appli_view {
  margin-top: 15px;
}

.job-post-add {
  position: unset;
  display: block;
  margin: auto;
  margin-right: 2%;
}

/* .tox.tox-silver-sink.tox-tinymce-aux {
  display: none;
} */

.ex-export span.anticon.anticon-file-excel {
  font-size: 30px;
  color: #109d59;
  display: inline-block;
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.ex-export {
  position: absolute;
  right: 5%;
  bottom: 5px;
}

.btn-exl {
  background: none;
  border: 0px;
}

label {
  cursor: pointer;
  /* Style as you please, it will become the visible UI component. */
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.view-attachment {
  width: 20px;
  font-size: 25px;
  margin: 0 4px;
  padding-top: 0;
}

.bg_gray {
  background: #f7f7f7;
  height: 200px;
  border: none;
}

.doc_icon {
  width: 40px;
}

.doc_icon-w {
  width: 25px;
}

.table_first_col {
  width: 70px !important;
}

.table_action_col {
  width: 150px !important;
}

.table_status_col {
  width: 150px !important;
}

.jop_btn {
  background-color: #1559a2;
  padding: 5px 15px;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  border-radius: 5px;
}

.jop_btn svg {
  width: 20px;
  margin-left: 2px;
  height: 22px;
  vertical-align: middle;
}

.char_count {
  float: right;
  font-size: 13px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.d-none {
  display: none;
}
.c-bl{
  color: #1890ff;
}
.frameSet{
  padding: 20px 10px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
}


.success-button {
  color: #212529;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 20px !important;
  text-align: center;
  border-radius: 10px;
  background: #4efe4e;
  cursor: pointer;
  outline: none;
  border: none;
  /* height: 35px; */
}
.success-button {
  color: #212529;
  text-transform: capitalize;
  font-size: 11px;
  font-weight: 500;
  padding: 3px 7px !important;
  text-align: center;
  border-radius: 10px;
  background: #4efe4e;
  cursor: pointer;
  outline: none;
  border: none;
  /* height: 35px; */
}
.delete-button {
  color: #fdfeff;
  text-transform: capitalize;
  font-size: 11px;
  font-weight: 500;
  padding: 3px 7px !important;
  text-align: center;
  border-radius: 10px;
  background: #ff1515;
  cursor: pointer;
  outline: none;
  border: none;
  /* height: 35px; */
}
.delete-button2 {
  color: #fdfeff;
  text-transform: capitalize;
  font-size: 11px;
  font-weight: 500;
  padding: 3px 7px !important;
  text-align: center;
  border-radius: 10px;
  background: #ff1515;
  cursor: pointer;
  outline: none;
  border: none;
  /* height: 35px; */
}

/* .form-select {
  width: 100%;
  height: 30px;
} */

.userDetailTable {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.userDetailTablet {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  background-color: #40189d;
  width: 100%;
}
.userDetailTablet2 {
  /* border: 1px solid #dddddd; */
  text-align: left;
  padding: 8px;
  background-color: #40189d;
  /* width: 100%; */
  color: #fff;
}

.companyName{
  font-size: 14px !important;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 5px;
  
}

.invotb tr:nth-child(odd) {
  background: rgba(0, 0, 0, 0.05) ;
}

.userDetailTable  tr {
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
}
.userDetailTable  td {
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
}

.coustomNevc {
  color: #009ef7 !important;
  cursor: pointer;
}
.coustomBlack {
  color: #000000 !important;
  cursor: pointer;
}
.coustomtrue {
  color: #00f742 !important;
  cursor: pointer;
}
.coustomtrue2 {
  color: #02b431 !important;
  cursor: pointer;
}
.coustomfalse {
  color: #f70000 !important;
  cursor: pointer;
}

.code-box {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 0 0 16px;
  background-color: #f0f2f5;
  border: 1px solid rgba(5, 5, 5, 0.06);
  border-radius: 6px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  padding: 15px;
}
.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100% !important;
}

.fs-5 {
  font-size: 1rem !important;
}

.fs-2 {
  font-size: calc(0.5rem + .9vw)!important;
}
.py-20 {
  padding: 2.7rem !important;
  /* padding-bottom: 5rem !important; */
}

.chatBoxHeight {
  height: 300px !important;
  /* overflow-y: scroll;
  position: relative; */
}

.scroll-y {
  overflow-y: scroll;
  position: relative;
  height: 300px !important;
}

.proadDImgContainer {
  position: relative;
  width: 100px;
}

.proadDImgBtn {
  position: absolute;
  top: -17px;
  right: -28px;
}

.dataTables_paginate.paging_simple_numbers.category {
  float: right;
}

.dataTables_wrapper .dataTables_paginate {
  padding: 10px 0;
}

.dataTables_paginate.paging_simple_numbers.category .previous a {
  /* color: #40189D; */
  color: var(--bs-pagination-color) !important;
}
.dataTables_paginate.paging_simple_numbers.category .next a {
  /* color: #40189D; */
  color: var(--bs-pagination-color) !important;
}
.dataTables_paginate.paging_simple_numbers.category li:nth-child(1) {
  background: transparent !important;
  color: var(--bs-pagination-color) !important;
}

.dataTables_paginate.paging_simple_numbers.category li{
  border: 0 !important;
  padding: 7px 16px;
  border: 0px solid #40189D !important;
  /* background: rgba(64, 24, 157, 0.3) !important; */
  border-radius: 1rem;
  color: var(--bs-pagination-color) !important;
  font-size: 16px;
  margin: 0;
  display: inline-block;
}
.bolgBox img{
max-width: 100%;
}

.dataTables_paginate.paging_simple_numbers.category li:hover{
     background: rgba(18, 18, 209, 0.1) !important;
}
/* .dataTables_paginate.paging_simple_numbers.category li:nth-child(1){

  background: transparent !important;
  
 
} */
.dataTables_paginate.paging_simple_numbers.category li:last-child{
 
  background: transparent !important;
  color: var(--bs-pagination-color) !important;
}
.fw-semibold .text-gray-600 tr td {
  color: var(--bs-pagination-color) !important;
}

.dataTables_paginate.paging_simple_numbers.category li.selected{
  color: #fff !important;
  background: #009ef7 !important;
}


.rankrole table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.rankrole td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.rankrole tr:nth-child(even) {
  background-color: #dddddd;
}